<template>
	<a-modal :okButtonProps="{ disabled: true }" :visible="showPassportScan" :title="`Scan Passport`" class="modal-large"
		@ok="scanToDetails" @cancel="$emit('close')" okText="NEXT" cancelText="CANCEL" centered>
		<a-card :key="newTime" class="my-3" :class="{
			'border-success':
				purchaserScan.scanData.firstName && !purchaserScan.scanError,
			'border-danger':
				purchaserScan.scanError ||
				(showScanError && !purchaserScan.filePassport),
		}">
			<div class="dF jSB aC">
				<span>
					<span>
						Passport Photo
						<a-icon v-if="purchaserScan.scanError ||
							(showScanError && !purchaserScan.filePassport)
							" type="close" class="text-danger" />
					</span>
					<small class="block text-sm" v-if="purchaserScan.scanError ||
						(showScanError && !purchaserScan.filePassport)
						">There was a problem retrieving data. Please scan
						again.</small>
					<small class="block text-sm" v-else-if="purchaserScan.scanData.firstName">{{ whoName }}:
						<strong>{{ purchaserScan.scanData.firstName }}
							{{ purchaserScan.scanData.lastName }}</strong></small>
				</span>
				<div>
					<a-upload :showUploadList="false" :beforeUpload="onFileUpload" v-if="!purchaserScan.scanData.firstName">
						<a-button type="secondary" icon="scan">Scan</a-button>
					</a-upload>
					<a-icon v-else type="check" class="text-success" />
				</div>
			</div>
		</a-card>
	</a-modal>
</template>

<script>
import { createWorker } from 'tesseract.js';
import { parse } from "mrz";
import moment from 'moment';

export default {
	props: ["options", "type", "whoName"],
	data: () => ({
		newTime: Date.now(),
		showScanError: false,
		purchaserScan: {
			scanError: false,
			filePassport: null,
			scanData: {},
			progress: null
		},
	}),
	computed: {
		showPassportScan() {
			return this.options.showPassportScan;
		},
	},
	watch: {
		showPassportScan(val) {
			if (!val) {
				this.purchaserScan = {
					scanError: false,
					filePassport: null,
					scanData: {},
					progress: null
				};
			}
		},
	},
	methods: {
		scanToDetails() {
			if (this.scanError())
				return this.$message.error(
					"Please review your images and try again"
				);
			const purchaser = this.parseScannedData(this.purchaserScan.scanData)

			if (this.options.callback) this.options.callback({ ...purchaser, idType: 'pp', filePassport: this.purchaserScan.filePassport });
			this.purchaserScan = {
				scanError: false,
				filePassport: null,
				scanData: {},
			};
			this.$emit("close");
		},

		async getFileData(file) {
			try {
				const worker = await createWorker({
					logger: m => this.progress = m.progress * 100,
				});
				await worker.loadLanguage('eng');
				await worker.initialize('eng');
				await worker.setParameters({
					lang: "OCRB",
					load_system_dawg: "F",
					load_freq_dawg: "F",
					load_unambig_dawg: "F",
					load_punc_dawg: "F",
					load_number_dawg: "F",
					load_fixed_length_dawgs: "F",
					load_bigram_dawg: "F",
					wordrec_enable_assoc: "F",
					tessedit_pageseg_mode: "6",
					tessedit_char_whitelist: "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789<"
				});
				const { data } = await worker.recognize(file);
				await worker.terminate();

				return data;
			} catch (error) {
				this.purchaserScan.scanError = true;
				console.error('Error while scanning passport', error)
			}
		},

		async onFileUpload(file) {
			this.$store.commit("LOAD", true);

			try {
				this.purchaserScan.scanData = {};
				this.purchaserScan.scanError = false;
				this.purchaserScan.filePassport = file;

				const data = await this.getFileData(file)
				const lines = data.lines
					.map(line => line.text)
					.map(text => text.replace(/ |\r\n|\r|\n/g, ""))
					.filter(text => text.includes("<<"))
					.filter(text => text.length < 48)
					.filter(text => text.length > 28);

				console.log('scan lines from passport', lines);
				if (lines.length > 1) {
					let result = this.checkMRZLines(lines)

					// Extract data manually if MRZ parser fail to do it
					if (!result) {
						result = this.extractLinesData(lines[0] || '', lines[1] || '')
					}

					if (result) {
						this.purchaserScan.scanData = result;
					} else {
						this.purchaserScan.scanError = true;
					}
				} else {
					this.purchaserScan.scanError = true;
				}
			} catch (error) {
				this.purchaserScan.scanError = true;
				console.error('Error while scanning passport', error)
			}

			this.$store.commit("LOAD", false);
			return false;
		},

		extractLinesData(line1, line2) {
			let passportMRZ = {};

			// let type = line1.substring(0, 1);
			// if (!(/[I|P|V]/.test(type))) return false;
			// if (type === 'P') {
			// 	passportMRZ.type = 'PASSPORT (TD-3)';
			// } else if (type === 'V') {
			// 	if (line1.length === 44) {
			// 		passportMRZ.type = 'VISA (MRV-A)';
			// 	} else if (line1.length === 36) {
			// 		passportMRZ.type = 'VISA (MRV-B)';
			// 	}
			// } else if (type === 'I') {
			// 	passportMRZ.type = 'ID CARD (TD-2)'
			// }

			// Get issuing State information
			let nation = line1.substring(2, 5);
			if (!/[0-9]/.test(nation)) {
				if (nation[nation.length - 1] === '<') {
					nation = nation.substr(0, 2);
				}
				passportMRZ.nationality = nation.trim();
			}

			// Get surname information
			line1 = line1.substring(5);
			var pos = line1.indexOf("<<");
			let lastName = line1.substring(0, pos);
			if (!/[0-9]/.test(lastName)) {
				lastName = lastName.replace(/\</g, " ");
				passportMRZ.lastName = lastName.trim();
			}

			// Get name information
			let firstName = line1.substring(lastName.length + 2);
			firstName = firstName.substring(0, firstName.indexOf('<<'))
			if (!/[0-9]/.test(firstName)) {
				firstName = firstName.replace(/\</g, " ");
				passportMRZ.firstName = firstName.trim();
			}

			// Get passport number information
			let documentNumber;
			documentNumber = line2.substring(0, 9);
			documentNumber = documentNumber.replace(/\</g, " ");
			passportMRZ.documentNumber = documentNumber.trim();

			// Get Nationality information
			let issuingState = line2.substr(10, 3);
			if (!/[0-9]/.test(issuingState)) {
				if (issuingState[issuingState.length - 1] == '<') {
					issuingState = issuingState.substr(0, 2);
				}
				passportMRZ.issuingState = issuingState.trim();
			}

			// Get date of birth information
			let birthDate = line2.substr(13, 6);
			// let date = new Date();
			// let currentYear = date.getFullYear();
			// if (parseInt(birthDate.substr(0, 2)) > (currentYear % 100)) {
			// 	birthDate = "19" + birthDate;
			// } else {
			// 	birthDate = "20" + birthDate;
			// }
			// birthDate = birthDate.slice(0, 4) + "-" + birthDate.slice(4, 6) + "-" + birthDate.slice(6);

			if (!/[A-Za-z]/.test(birthDate)) {
				passportMRZ.birthDate = birthDate;
			}

			// // Get gender information
			// let gender = line2[20];
			// if ((/[M|F|x|<]/.test(gender))) {
			// 	passportMRZ.gender = gender;
			// }

			// Get date of expirationDate information
			let expirationDate = line2.substr(21, 6);
			// if (parseInt(expirationDate.substring(0, 2)) >= 60) {
			// 	expirationDate = '19' + expirationDate;
			// } else {
			// 	expirationDate = '20' + expirationDate;
			// }
			// expirationDate = expirationDate.slice(0, 4) + "-" + expirationDate.slice(4, 6) + "-" + expirationDate.slice(6);
			if (!/[A-Za-z]/.test(expirationDate)) {
				passportMRZ.expirationDate = expirationDate;
			}

			console.log('passportMRZ', passportMRZ)
			return passportMRZ;
		},

		checkMRZLines(lines) {
			try {
				const result = lines ? parse(lines) : { valid: false };
				if (result && result.fields) {
					console.log('able to parse field from mzr parser')
					return result.fields
				}
			} catch (e) {
				console.error('Error while parsing passport data using mzr parser')
			}
		},

		parseScannedData(data) {
			const fieldsMapping = {
				firstName: 'firstName',
				lastName: 'lastName',
				birthDate: 'dob',
				nationality: 'country',
				issuingState: 'passportOtherIssuing',
				documentNumber: 'passportOtherValue',
				expirationDate: 'passportOtherExpiry'
			}

			let purchaser = {}

			for (const key in data) {
				if (Object.hasOwnProperty.call(data, key)) {
					if (fieldsMapping[key]) {
						if (fieldsMapping[key] === 'dob') {
							purchaser[fieldsMapping[key]] = moment(data[key], 'YYMMDD').isValid() ? moment(data[key], 'YYMMDD').format('YYYY/MM/DD') : null
						} else if (fieldsMapping[key] === 'passportOtherExpiry') {
							purchaser[fieldsMapping[key]] = moment(data[key], 'YYMMDD').isValid() ? moment(data[key], 'YYMMDD').format('YYYY/MM/DD') : null
						} else {
							purchaser[fieldsMapping[key]] = data[key]
						}
					}
				}
			}

			console.log('Purchaser\'s details', purchaser)
			return purchaser
		},

		scanError() {
			this.showScanError = false;
			if (
				!this.purchaserScan.scanData.firstName
			) {
				this.showScanError = true;
				return true;
			}
			return false;
		},
	},
	created() {
		// This is test lines
		// this.checkMRZLines(['P<ITAVIGNI<<PIETRO<<<<<<<<<<<<<<<<<<<<<<<<<<', 'YA03052961ITA7809268M2005180<<<<<<<<<<<<<<00'])
	},
};
</script>

<style>
</style>
