var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{staticClass:"modal-large",attrs:{"okButtonProps":{ disabled: true },"visible":_vm.showPassportScan,"title":`Scan Passport`,"okText":"NEXT","cancelText":"CANCEL","centered":""},on:{"ok":_vm.scanToDetails,"cancel":function($event){return _vm.$emit('close')}}},[_c('a-card',{key:_vm.newTime,staticClass:"my-3",class:{
		'border-success':
			_vm.purchaserScan.scanData.firstName && !_vm.purchaserScan.scanError,
		'border-danger':
			_vm.purchaserScan.scanError ||
			(_vm.showScanError && !_vm.purchaserScan.filePassport),
	}},[_c('div',{staticClass:"dF jSB aC"},[_c('span',[_c('span',[_vm._v(" Passport Photo "),(_vm.purchaserScan.scanError ||
						(_vm.showScanError && !_vm.purchaserScan.filePassport)
						)?_c('a-icon',{staticClass:"text-danger",attrs:{"type":"close"}}):_vm._e()],1),(_vm.purchaserScan.scanError ||
					(_vm.showScanError && !_vm.purchaserScan.filePassport)
					)?_c('small',{staticClass:"block text-sm"},[_vm._v("There was a problem retrieving data. Please scan again.")]):(_vm.purchaserScan.scanData.firstName)?_c('small',{staticClass:"block text-sm"},[_vm._v(_vm._s(_vm.whoName)+": "),_c('strong',[_vm._v(_vm._s(_vm.purchaserScan.scanData.firstName)+" "+_vm._s(_vm.purchaserScan.scanData.lastName))])]):_vm._e()]),_c('div',[(!_vm.purchaserScan.scanData.firstName)?_c('a-upload',{attrs:{"showUploadList":false,"beforeUpload":_vm.onFileUpload}},[_c('a-button',{attrs:{"type":"secondary","icon":"scan"}},[_vm._v("Scan")])],1):_c('a-icon',{staticClass:"text-success",attrs:{"type":"check"}})],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }