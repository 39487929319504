<template>
    <a-select
        :value="multiple? personList : person"
        :mode="multiple?'multiple':'single'"
        show-search
        :show-arrow="false"
        placeholder="Type to search..."
        :filter-option="false"
        @search="suggestContact"
        :not-found-content="null"
        :options="queriedContacts"
        @change="handleChange"
        >
    </a-select>
</template>

<script>
export default {
    props: {
        multiple: {
            default: false,
            type: Boolean,
        }
    },
    watch:{

    },
    data() {
        return {
            timeout:null,
            queriedContacts:[],
            person:'',
            personList:[],
        }
    },
    computed:{

    },
    methods: {
        async updateList(input) {
			try {
				let { data } = await this.$api.get(`/contacts/:instance/autocomplete?q=${input}`)
				this.queriedContacts = data.map(({ name, id, email }) => {
					let obj = {}
					if (!name.trim() || !name) obj.label = email
					else obj.label = name + ' (' + email + ')'
					obj.value = id
					return obj
				})
			} catch (err) {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			}
		},
        suggestContact(input){
            if (input.length < 3){
                return this.timerId = undefined
            }
            const  makeAPICall = () => {
                this.updateList(input)
            }

            if (this.timerId)  clearTimeout(this.timerId)

            this.timerId  =  setTimeout(function () {
                makeAPICall()
                this.timerId  =  undefined;
            }, 400)
        },
        handleChange(value){
            if (!this.multiple){
                let index = this.queriedContacts.findIndex(x => x.value == value)
                this.person = this.queriedContacts[index] && this.queriedContacts[index].label
            }
            this.$emit('updatecontact', value)
        }
    }
}
</script>

<style>

</style>
